import { RemixBrowser } from '@remix-run/react'
import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { StrictMode, startTransition } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { I18nextProvider, initReactI18next } from 'react-i18next'

import { i18nSharedConfig } from './i18n'
import { sentryInit } from './sentry/sentry.client'

import { environment } from '~/config'
import { Wallet, walletContext } from '~/tenset-web3'

async function hydrate() {
  await i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
      ...i18nSharedConfig,
      backend: {
        loadPath: `/locales/{{lng}}/{{ns}}.json?ver=${environment.VERSION || 1}`,
      },
      detection: {
        order: ['htmlTag'],
        caches: [],
      },
    })

  const wallet = new Wallet()

  startTransition(() => {
    hydrateRoot(
      document,
      <StrictMode>
        <I18nextProvider i18n={i18next}>
          <walletContext.Provider value={wallet}>
            <RemixBrowser />
          </walletContext.Provider>
        </I18nextProvider>
      </StrictMode>
    )
  })
}

if (environment.SENTRY_ENABLED) sentryInit()

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate)
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1)
}
