import { useLocation, useMatches } from '@remix-run/react'
import * as Sentry from '@sentry/remix'
import { useEffect } from 'react'

import { SENTRY_SHARED_CONFIGURATION } from './shared'

export function sentryInit() {
  Sentry.init({
    ...SENTRY_SHARED_CONFIGURATION,
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
    ],
  })
}
